<template>
  <div>
    <div style="margin: 10px 0px">
      Format
      <a-select style="width: 200px" :default-value="inputFormat" @change="change">
        <a-select-option v-for="format in DATE_FORMATS" :key="format" :value="format">
          {{ format }}
        </a-select-option>
      </a-select>
    </div>
  </div>
</template>

<script>
import FieldInputOptionsMixin from '../../FieldInputOptionsMixin';

const DATE_FORMATS = ['DD.MM.YYYY', 'DD-MM-YYYY', 'YYYY-MM-DD', 'MM-DD-YYYY'];

export default {
  mixins: [FieldInputOptionsMixin],
  fieldInputOptions: {
    path: 'structure.options',
    keys: ['format'],
  },
  data() {
    return {
      DATE_FORMATS,
    };
  },
  computed: {
    inputFormat() {
      return this.input?.options?.format ?? this.input?.format ?? 'DD.MM.YYYY';
    },
  },
  methods: {
    change(value) {
      if (this.input?.options?.format) {
        this.input.options.format = value;
      } else {
        this.input.format = value;
      }
    },
  },
};
</script>

<style></style>
