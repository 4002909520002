<template>
  <div v-if="!viewingMode">
    <a-select
      :getPopupContainer="trigger => trigger.parentElement"
      :mode="isGroup ? 'single' : 'multiple'"
      v-model:value="valueComputed"
      :required="field.isRequired"
      style="min-width: 200px; width: 100%"
      :placeholder="inputPlaceholder"
      :size="inputSize"
      :auto-focus="autoFocus"
      :defaultOpen="openDropdown"
      :disabled="readOnly || (field.isReadOnlyAdd && addNew) || (field.isReadOnlyEdit && !addNew)"
      :open="selectOpen"
      @change="handleSelectChange"
      @dropdownVisibleChange="handleDropdownVisibleChange"
    >
      <a-select-option v-for="choice in field.structure.elementStructure.choices" :value="choice.value" :key="choice.value">
        {{ choice.label || choice.value }}
      </a-select-option>
    </a-select>
  </div>

  <div v-else>
    {{ rawValueToString }}
  </div>
</template>

<script>
import InputMixin from './InputMixin';

export default {
  props: ['indexValue'],
  mixins: [InputMixin],
  data() {
    return {
      selectOpen: false,
    };
  },
  computed: {
    valueComputed: {
      set(value) {
        if (this.isGroup) {
          const allValues = [...this.inputValue];
          allValues[this.indexValue] = value;
          this.inputValue = allValues;
        } else {
          this.inputValue = value;
        }
      },
      get() {
        if (this.isGroup) {
          return (this.inputValue && this.inputValue[this.indexValue]) ?? [];
        }
        return this.inputValue ?? [];
      },
    },
    rawValueToString() {
      if (this.rawValue && Array.isArray(this.rawValue)) {
        return this.rawValue.join(', ');
      }
      return '';
    },
  },
  methods: {
    handleSelectChange() {
      this.selectOpen = false;
    },
    handleDropdownVisibleChange(visible) {
      this.selectOpen = visible;
    },
    // onChange(value) {
    //   if (Array.isArray(value)) {
    //     this.inputValue = value.sort();
    //   } else {
    //     this.inputValue = [value];
    //   }
    // },
  },
};
</script>
