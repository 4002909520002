import axios from 'axios';

import { SlugGetters } from '@/slug.store';

const { getAppUrlWithGlobal } = SlugGetters;

const postOne = async ({ blueprintId, widgetType, structureRuleset }) => {
  const response = await axios.post(`${getAppUrlWithGlobal()}/data-system/fields?blueprintId=${blueprintId}`, {
    widgetType,
    structureRuleset,
  });
  return response.data;
};

const patchOne = async (fieldId, fieldInput) => {
  const response = await axios.patch(`${getAppUrlWithGlobal()}/data-system/fields/${fieldId}`, fieldInput);
  return response.data;
};

const deleteOne = async fieldId => {
  const response = await axios.delete(`${getAppUrlWithGlobal()}/data-system/fields/${fieldId}`);
  return response.data;
};

export default {
  postOne,
  patchOne,
  deleteOne,
};
