import { instanceApi } from '@dataSystem/api';
import { NewTemplatesGetters } from '@templateDataSystem/shared/newTemplateDataSystem.store';

let lastTriggerFieldId = null;
let lastTriggerValue = null;
const initAutoComplete = async () => {
  lastTriggerFieldId = null;
  lastTriggerValue = null;
};
/**
 * This function can modify multiple fields from multiple triggers
 * @param autoComplete -- field trigger = key and value[array] fields to be modified   field.key = [array]
 * @param modifiedFieldId -- field modified by user ( check if is the trigger
 * @param fieldIdToValue -- all fields and values
 * @param fields -- all fields objects
 * @param objectId -- template object
 * @returns {Promise<*>} -- return  fieldIdToValue all fields and values modified
 */
const setAutocomplete = async (autoComplete, modifiedFieldId, fieldIdToValue, fields, objectId) => {
  const autocompleteModifiedFields = [];
  const modifiedFieldIdToValue = fieldIdToValue;

  if (
    Object.prototype.hasOwnProperty.call(autoComplete, modifiedFieldId) &&
    modifiedFieldIdToValue[modifiedFieldId] &&
    (lastTriggerFieldId !== modifiedFieldId || lastTriggerValue !== modifiedFieldIdToValue[modifiedFieldId])
  ) {
    lastTriggerFieldId = modifiedFieldId;
    lastTriggerValue = modifiedFieldIdToValue[modifiedFieldId];
    const template = await NewTemplatesGetters.getTemplate('main');
    const params = {
      instanceId: modifiedFieldIdToValue[modifiedFieldId],
      useAutoComplete: true,
      templateId: template._id,
      objectId,
    };
    const field = fields.find(f => f.id.toString() === modifiedFieldId);

    const { instanceList } = await instanceApi.getAll(field.structure.ruleset.blueprintId, params);
    for (let i = 0; i < autoComplete[modifiedFieldId]?.length; i += 1) {
      const autocompleteField = autoComplete[modifiedFieldId][i];
      if (autocompleteField in instanceList[0]) {
        if (Array.isArray(instanceList[0][autocompleteField])) {
          [modifiedFieldIdToValue[autocompleteField]] = instanceList[0][autocompleteField];
        } else {
          modifiedFieldIdToValue[autocompleteField] = instanceList[0][autocompleteField];
        }
        autocompleteModifiedFields.push(autocompleteField);
      }
    }
  }
  return { modifiedFieldIdToValue, autocompleteModifiedFields };
};
export default {
  initAutoComplete,
  setAutocomplete,
};
