import { blueprintApi, instanceApi } from '@dataSystem/api';

import createMathjsInstance from '@/core/utils/create-mathjs-instance';
import _ from 'lodash';
import FieldValueHelper from './helpers/fieldValue.helper';
import ConditionalLogicHelper from './helpers/conditionalLogic.helper';
import { ValidationHelper } from './helpers/validation.helper';

import AutoComplete from './helpers/autoComplete.helper';

const BlueprintFormLogicMixin = {
  props: [
    'userRolesWhichCanCreate',
    'userRolesWhichCanDelete',
    'blueprintId',
    'propsInstance',
    'instanceId',
    'propsBlueprint',
    'propsFieldsById',
    'clearAfterSubmit',
    'autoComplete',
    'objectId',
  ],
  emits: ['update:modelValue', 'submit', 'delete', 'cancel'],
  data() {
    return {
      math: null,
      blueprint: null,
      instance: null,
      fieldsById: null,
      waitForValidityById: {},
      ...FieldValueHelper.initialData,
      ...ConditionalLogicHelper.initialData,
      isFormLogicInitDone: false,
      isValidationInitDone: false,
      computeFieldsPropsTimeout: null,
      computeFieldsPropsRecalculateTimeout: null,
      fieldIdToValidity: {},
      fieldIdToValidationMessage: {},
      lastModifiedField: null,
      validationHelper: new ValidationHelper(),
      modifiedFieldId: [],
      fieldIdToValidationFailedArray: {},
      enableAutocomplete: false, // enable autocomplete after full load and validated
    };
  },
  async created() {
    this.validationHelper = new ValidationHelper();
    this.math = createMathjsInstance();
    this.math.import({
      field: this.getFieldValue,
    });

    if (this.blueprintId) {
      await this.fetchBlueprint();
    }

    if (this.instanceId && !this.propsInstance) {
      await this.fetchInstance();
    }

    if (this.propsBlueprint && this.propsFieldsById) {
      this.blueprint = this.propsBlueprint;
      this.fieldsById = this.propsFieldsById;
    }

    if (this.propsInstance) {
      this.instance = this.propsInstance;
    }

    if (this.blueprint && this.fieldsById) {
      await this.init();
    }
  },
  computed: {
    fields() {
      return FieldValueHelper.getFields({
        blueprint: this.blueprint,
        fieldsById: this.fieldsById,
      });
    },
    isValidationPassed() {
      const fieldsValidity = { ...this.fieldIdToValidity };
      for (let i = 0; i < Object.keys(this.fieldIdToValidity)?.length; i += 1) {
        const fieldId = Object.keys(this.fieldIdToValidity)[i];
        fieldsValidity[fieldId].visibility = this.fieldIdToIsVisible[fieldId];
        let findField = false;
        if (fieldsValidity[fieldId]?.validationFieldArrayIndex !== -1) {
          this.fieldIdToValidationFailedArray[fieldId] = fieldsValidity[fieldId]?.validationFieldArrayIndex;
        } else if (this.fieldIdToValidationFailedArray[fieldId]) {
          delete this.fieldIdToValidationFailedArray[fieldId];
        }
        for (let j = 0; j < Object.keys(this.filterFields).length; j += 1) {
          const field = Object.keys(this.filterFields)[j];
          if (this.filterFields[field]._id.toString() === fieldId) {
            findField = true;
          }
        }
        if (!findField) {
          fieldsValidity[fieldId].visibility = false;
        }
      }
      return ValidationHelper.computeIsValidationPassed({
        fieldIdToValidity: fieldsValidity,
      });
    },
    computedfieldIdToValue() {
      return _.cloneDeep(this.fieldIdToValue);
    },
  },
  watch: {
    propsInstance(data) {
      this.instance = data;
      // this.fieldIdToValue = data;
    },
    instanceId() {
      this.init();
    },

    computedfieldIdToValue: {
      deep: true,
      async handler(newVal, oldVal) {
        this.checkModifiedFields(newVal, oldVal);
      },
    },
  },
  methods: {
    checkModifiedFields(newVal, oldVal) {
      const modifiedFieldId = _.cloneDeep(this.modifiedFieldId);
      if (this.isFormLogicInitDone) {
        // validate from buffer for modifiedFieldId
        for (let index = 0; index < modifiedFieldId.length; index += 1) {
          if (this.lastModifiedField === modifiedFieldId[index]) {
            // clear timeout if same field
            clearTimeout(this.computeFieldsPropsTimeout);
          }
          this.lastModifiedField = modifiedFieldId[index];
          if (
            this.fieldsById[modifiedFieldId[index]]?.structure?.type !== 'string' ||
            this.fieldsById[modifiedFieldId[index]]?.structure?.choices?.length
          ) {
            // allow only input strings to have 500 milliseconds delay, others have 50 milliseconds delay
            this.computeFieldsPropsTimeout = setTimeout(async () => {
              await this.computeFieldProperties(newVal, oldVal, modifiedFieldId[index], false);
            }, 50);
          } else {
            this.computeFieldsPropsTimeout = setTimeout(async () => {
              await this.computeFieldProperties(newVal, oldVal, modifiedFieldId[index], false);
            }, 500);
          }
        }
        // enable autocomplete after all first validation it`s passed , node: check timeouts
        if (!this.enableAutocomplete) {
          setTimeout(async () => {
            this.enableAutocomplete = true;
          }, 600);
        }
        this.modifiedFieldId = [];
      }
    },
    async computeFieldProperties(newVal, oldVal, modifiedFieldId, recalculate = true) {
      if (!modifiedFieldId) {
        // if null stop
        return;
      }
      const multipleFieldIdToValidity = _.cloneDeep(this.fieldIdToValidity); // all modifies stored in new variable, to make computed execute once
      if (modifiedFieldId && this.autoComplete && this.enableAutocomplete) {
        this.waitForValidityById[modifiedFieldId] = true;
        if (Object.keys(this.autoComplete).length) {
          // execute only if autocomplete is active on field and validate all field from autocomplete
          const {
            modifiedFieldIdToValue,
            autocompleteModifiedFields, // fields to be validated
          } = await AutoComplete.setAutocomplete(this.autoComplete, modifiedFieldId, this.fieldIdToValue, this.fields, this.objectId);
          this.fieldIdToValue = modifiedFieldIdToValue;

          const promises = autocompleteModifiedFields.map(modifiedFieldIdByAutocomplete =>
            this.validationHelper.computeReferenced({
              fieldsById: this.fieldsById,
              fieldIdToValue: this.fieldIdToValue,
              oldFieldIdToValue: oldVal,
              fieldIdToValidity: multipleFieldIdToValidity,
              waitForValidityById: this.waitForValidityById,
              isValidationInitDone: !this.isValidationInitDone,
              modifiedFieldId: modifiedFieldIdByAutocomplete,
            })
          );

          // Wait for all promises to complete
          const results = await Promise.all(promises);

          // Optionally update `multipleFieldIdToValidity` based on results
          // If `computeReferenced` needs to accumulate results, you need to merge them appropriately
          results.forEach(result => {
            // Assuming `result` contains the updated validity for a specific field
            // Update `multipleFieldIdToValidity` as needed
            Object.assign(multipleFieldIdToValidity, result);
          });
        }
      }
      const fieldsValidity = await this.validationHelper.computeReferenced({
        // validate modified field
        fieldsById: this.fieldsById,
        fieldIdToValue: this.fieldIdToValue,
        oldFieldIdToValue: oldVal,
        fieldIdToValidity: multipleFieldIdToValidity,
        waitForValidityById: this.waitForValidityById,
        isValidationInitDone: !this.isValidationInitDone,
        modifiedFieldId,
        fieldIdToIsVisible: this.fieldIdToIsVisible,
      });

      Object.assign(this.fieldIdToValidity, fieldsValidity);
      const newFieldIdToValue = FieldValueHelper.computeFieldIdToValue({
        math: this.math,
        fieldsById: this.fieldsById,
        fieldIdToValue: this.fieldIdToValue,
        fieldIdToCalculationDependencies: this.fieldIdToCalculationDependencies,
      });
      for (let i = 0; i < Object.keys(this.fieldsById)?.length; i += 1) {
        const fieldId = Object.keys(this.fieldsById)[i];
        if (oldVal[fieldId] !== this.fieldIdToValue[fieldId]) {
          this.waitForValidityById[fieldId] = false;
        }
      }
      if (!_.isEqual(this.fieldIdToValue, newFieldIdToValue)) {
        this.fieldIdToValue = newFieldIdToValue;
      }
      const newFieldIdToIsVisible = await ConditionalLogicHelper.computeFieldIdToIsVisible({
        fieldsById: this.fieldsById,
        fieldIdToValue: this.fieldIdToValue,
        fieldIdToConditionalDependencies: this.fieldIdToConditionalDependencies,
        fieldIdToIsVisible: this.fieldIdToIsVisible,
        fieldIdToValidity: this.fieldIdToValidity,
      });
      this.fieldIdToIsVisible = newFieldIdToIsVisible;

      if (recalculate) {
        clearTimeout(this.computeFieldsPropsRecalculateTimeout);

        this.computeFieldsPropsRecalculateTimeout = setTimeout(async () => {
          await this.computeFieldProperties(newVal, oldVal, null, false);
        }, 50);
      }
      if (Object.keys(oldVal).length > 0) {
        // execute 1 time old values validation
        this.isValidationInitDone = true;
      }
    },
    onSubmit() {
      this.$emit('submit', this.fieldIdToValue);
      if (this.clearAfterSubmit) {
        this.init();
      }
    },
    onDelete() {
      this.$emit('delete', this.fieldIdToValue);
    },
    onCancel() {
      this.$emit('cancel');
    },
    async fetchBlueprint() {
      const { blueprint, fieldsById } = await blueprintApi.getOne(this.blueprintId, this.formSlug);
      this.blueprint = blueprint;
      this.fieldsById = fieldsById;
    },
    async fetchInstance() {
      if (this.propsInstance) {
        this.instance = this.propsInstance;
      } else if (this.instanceId) {
        this.instance = await instanceApi.getOne(this.blueprintId, this.instanceId, {}, this.formSlug);
      }
    },
    getFieldValue(fieldSuccessionIndex) {
      const field = Object.values(this.fieldsById).find(f => f.successionIndex === fieldSuccessionIndex);
      return this.fieldIdToValue[field._id] || 0;
    },
    async init() {
      await this.fetchInstance();
      const { fieldIdToValue, fieldIdToCalculationDependencies } = FieldValueHelper.init({
        fieldsById: this.fieldsById,
        instance: this.instance,
      });
      this.fieldIdToValue = fieldIdToValue;
      this.fieldIdToCalculationDependencies = fieldIdToCalculationDependencies;

      const { fieldIdToIsVisible, fieldIdToConditionalDependencies } = ConditionalLogicHelper.init({
        fieldsById: this.fieldsById,
        fieldIdToValue,
      });
      this.fieldIdToIsVisible = fieldIdToIsVisible;
      this.fieldIdToConditionalDependencies = fieldIdToConditionalDependencies;

      const { fieldIdToValidity } = await this.validationHelper.initReferenced({
        fieldsById: this.fieldsById,
        fieldIdToValue: this.fieldIdToValue,
      });

      this.fieldIdToValidity = fieldIdToValidity;

      await AutoComplete.initAutoComplete();
      this.isFormLogicInitDone = true;

      this.checkModifiedFields(this.fieldIdToValue, {});

      // this.fieldIdToValue = fieldIdToValue;
    },
  },
};

export default BlueprintFormLogicMixin;
