<template>
  <div v-if="!viewingMode">
    <a-select
      :getPopupContainer="trigger => trigger.parentElement"
      :allowClear="true"
      v-model:value="inputValue"
      :required="field.isRequired"
      style="width: 100%"
      :placeholder="inputPlaceholder"
      :size="inputSize"
      :auto-focus="autoFocus"
      :defaultOpen="openDropdown"
      :disabled="readOnly || (field.isReadOnlyAdd && addNew) || (field.isReadOnlyEdit && !addNew)"
      @blur="emitBlur"
      :open="selectOpen"
      @change="handleSelectChange"
      @dropdownVisibleChange="handleDropdownVisibleChange"
    >
      <a-select-option v-for="choice in field.structure.choices" :value="choice.value" :key="choice.value">
        {{ choice.label || choice.value }}
      </a-select-option>
    </a-select>
  </div>

  <div v-else>
    {{ rawValue }}
  </div>
</template>

<script>
import InputMixin from './InputMixin';

export default {
  mixins: [InputMixin],
  emits: ['blur', 'pressEnter'],
  data() {
    return {
      selectOpen: false,
    };
  },
  mounted() {
    window.addEventListener('keydown', this.onKeyDown);
  },
  beforeUnmount() {
    window.addEventListener('keydown', this.onKeyDown);
  },
  methods: {
    handleSelectChange() {
      this.selectOpen = false;
    },
    handleDropdownVisibleChange(visible) {
      this.selectOpen = visible;
    },
    emitBlur(selectedValue) {
      this.$emit('blur', {
        target: {
          value: selectedValue,
        },
      });
    },
    onKeyDown(e) {
      if (e.key === 'Enter') {
        this.$emit('pressEnter', {
          target: {
            value: this.inputValue,
          },
        });
      }
    },
  },
};
</script>
