<template>
  <div>
    <a-row type="flex">
      <a-col flex="250px" class="mobile-role-cols">
        <RoleSelect :roles="roles" @select="onRoleSelect" />
      </a-col>
      <a-col flex="250px" class="mobile-role-cols">
        <RoleUsers v-if="selectedRole" :role="selectedRole" @insert="onInsertUsers" @remove="onUserRemove" />
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { roleSubtenantApi } from '@roleManagement/api';
import { blueprintApi } from '@dataSystem/api';
import RoleUsers from './components/RoleUsers.vue';
import RoleSelect from './components/RoleSelect.vue';

export default {
  data() {
    return {
      roles: [],
      blueprints: [],
      selectedRole: null,
    };
  },
  created() {
    this.fetchRoles();
    this.fetchBlueprints();
  },
  components: {
    RoleSelect,
    RoleUsers,
  },
  computed: {
    blueprintById() {
      return Object.fromEntries(this.blueprints.map(blueprint => [blueprint._id, blueprint]));
    },
  },
  methods: {
    setUpdatedRole(updatedRole) {
      const roleIndex = this.roles.findIndex(role => role._id === updatedRole._id);
      this.roles[roleIndex] = updatedRole;

      if (this.selectedRole._id === updatedRole._id) {
        this.selectedRole = updatedRole;
      }
    },
    onRoleSelect(selectedRoleId) {
      this.selectedRole = this.roles.find(role => role._id === selectedRoleId);
    },
    async fetchBlueprints() {
      this.blueprints = await blueprintApi.getAll();
    },
    async fetchRoles() {
      this.roles = await roleSubtenantApi.getAllForSubtenant();
    },
    async onUserRemove(userIdToRemove) {
      const updatedRole = await roleSubtenantApi.deleteUser({
        roleId: this.selectedRole._id,
        userId: userIdToRemove,
      });
      this.setUpdatedRole(updatedRole);
    },
    async onInsertUsers(userIdListToInsert) {
      const updatedRole = await roleSubtenantApi.postUsers({
        roleId: this.selectedRole._id,
        userIdList: userIdListToInsert,
      });
      this.setUpdatedRole(updatedRole);
    },
  },
};
</script>

<style>
@media screen and (max-width: 600px) {
  .mobile-role-cols {
    flex: auto !important;
    width: 100%;
    display: block;
  }
  .mobile-role-cols ul {
    margin: 24px 0px 0px !important;
  }
}
</style>
