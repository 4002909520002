<template>
  <div id="app">
    <NavbarComponent :user="user" v-if="user" />
    <div class="container app-container">
      <router-view />
    </div>
    <FooterComponent />
  </div>
</template>

<script>
import { UserGetters } from '@userManagement/user.store';
import NavbarComponent from './apps/common/views/Header/Navbar.vue';
import FooterComponent from './apps/common/views/Footer/Footer.vue';

export default {
  data() {
    return {
      isLoading: true,
    };
  },
  components: {
    NavbarComponent,
    FooterComponent,
  },
  computed: {
    user: UserGetters.getUser,
  },
};
</script>
<style></style>
