import { reactive } from 'vue';

// eslint-disable-next-line import/no-cycle
import { instanceApi } from '@dataSystem/api';
import { templatesDataSystemApi, publicTemplatesDataSystemApi } from '@templateDataSystem/api/';

import BlueprintApi from '@dataSystem/api/blueprint.api';
// eslint-disable-next-line import/no-cycle
import { FilterConditions } from '@templateDataSystem/shared/FilterConditions';
// eslint-disable-next-line import/no-cycle
import { DynamicTemplate } from '@templateDataSystem/shared/DynamicTemplate';
import { isEmpty } from '@/core/utils/string-manipulation';
import { roleSubtenantApi } from '@roleManagement/api';

const initialState = {
  roles: {},
  publicTemplate: false,
  downloadPDF: { showButton: false, actionButton: false },
  downloadXLSX: {
    status: false,
    templateIdList: [],
    blueprintIdList: [],
    columnsList: [],
    workbooksNames: [],
  },
  templateLoading: false,
  fetchInstanceLoading: {},
  fetchInstanceListLoading: {},

  template: {},

  //
  blueprintIds: [],
  instanceListIds: [],
  //

  blueprints: [], // all blueprints

  //
  mainInstance: null,
  instanceByBlueprint: {},
  //

  instanceList: {},
  instanceListQueryParams: {},

  //
  templateHtml: [],
  contentHtml: [],
  contentHtmlAfter: [],
  contentHtmlLoading: {},
  //

  updatePagination: {},
  openModals: {},
  createdEditedDeletedInstance: {
    status: false,
    blueprintId: null,
    objectId: null,
  },

  filterConditions: {},
  lastQuery: {},
};

const state = reactive({ ...initialState });

const Getters = {
  getRoles: () => {
    return state.roles;
  },
  getIsPublicTemplate() {
    return state.publicTemplate;
  },
  getDownloadPDF() {
    return state.downloadPDF;
  },
  getDownloadXLSX() {
    return state.downloadXLSX;
  },

  getTemplate: id => {
    return state.template[id] ?? null;
  },
  getTemplateLoading: () => {
    return state.templateLoading;
  },
  getInstanceLoading: (objectId, blueprintId) => {
    return (state.fetchInstanceLoading[objectId] && state.fetchInstanceLoading[objectId][blueprintId]) ?? false;
  },

  getInstanceListLoading: objectId => {
    return state.fetchInstanceListLoading[objectId] ?? false;
  },

  getBlueprint: blueprintId => {
    if (!blueprintId) {
      return null;
    }
    return state.blueprints.find(item => item._id.toString() === blueprintId.toString());
  },
  getInstance: (blueprintId, instanceId) => {
    const instance = state.instanceByBlueprint[blueprintId] ?? null;
    if (instanceId) {
      if (instance && instance._id.toString() === instanceId.toString()) {
        return instance;
      }
      return null;
    }
    return state.instanceByBlueprint[blueprintId] ?? null;
  },
  getInstanceByBlueprint: blueprintId => {
    return state.instanceByBlueprint[blueprintId] ?? null;
  },
  getAllInstanceByBlueprint: () => {
    return state.instanceByBlueprint;
  },
  getInstanceListQueryParams: (objectId, blueprintId) => {
    if (blueprintId) {
      return (state.instanceListQueryParams[objectId] && state.instanceListQueryParams[objectId][blueprintId]) ?? {};
    }
    return state.instanceListQueryParams[objectId] ?? {};
  },
  getMainInstance: () => {
    return state.mainInstance;
  },
  getInstanceList: (objectId, blueprintId) => {
    return (state.instanceList[objectId] && state.instanceList[objectId][blueprintId]) ?? null;
  },

  getContentHtml: (objectId, contentType) => {
    return state.contentHtml[`${objectId}-${contentType}`] ?? null;
  },
  getContentHtmlAfter: (objectId, contentType) => {
    return state.contentHtmlAfter[`${objectId}-${contentType}`] ?? null;
  },
  getContentHtmlLoading: objectId => {
    return state.contentHtmlLoading[objectId] ?? false;
  },

  //
  getUpdatePagination: objectId => {
    return state.updatePagination[objectId] ?? null;
  },
  getIsOpenModal(objectId) {
    return state.openModals[objectId] ?? false;
  },
  getCreatedEditedDeletedInstance() {
    return state.createdEditedDeletedInstance;
  },

  getFilterConditions: objectId => {
    return state.filterConditions[objectId] ?? [];
  },
  getLastQuery(objectId, blueprintId) {
    if (objectId) {
      return state.lastQuery[objectId][blueprintId.toString()];
    }
    return {};
  },
};

const Mutations = {
  SET_INSTANCE_LIST_QUERY_PARAMS: (objectId, blueprintId, query) => {
    state.instanceListQueryParams = {
      ...state.instanceListQueryParams,
      [objectId]: { [blueprintId]: query },
    };
  },
  SET_UPDATE_PAGINATION: (objectId, data) => {
    state.updatePagination = {
      ...state.updatePagination,
      [objectId]: data,
    };
  },
  SET_OPEN_CLOSE_MODAL: (objectId, value, reset = false) => {
    if (reset) {
      state.openModals = {};
    } else {
      state.openModals = {
        ...state.openModals,
        [objectId]: value,
      };
    }
  },
  SET_INSTANCE: (blueprintId, instance) => {
    if (state.mainInstance?._id === instance._id) {
      state.mainInstance = instance;
    }
    state.instanceByBlueprint = {
      ...state.instanceByBlueprint,
      [blueprintId]: instance,
    };
  },

  SET_DOWNLOAD_PDF(showButton, actionButton) {
    state.downloadPDF = { showButton, actionButton };
  },
  SET_DOWNLOAD_XLSX(status, templateIdList, blueprintIdList, columnsList, workbooksNames) {
    state.downloadXLSX = {
      status,
      templateIdList,
      blueprintIdList,
      columnsList,
      workbooksNames,
    };
  },
};

const Actions = {
  /* eslint-disable-next-line no-unused-vars */
  init: async (templateId, mainInstanceId = null, publicTemplate = false) => {
    state.templateLoading = true;
    state.instanceListIds = [];
    state.instanceList = {};
    state.templateHtml = [];
    state.publicTemplate = publicTemplate;

    const template = await Actions.fetchTemplate(templateId);
    const { blueprintId } = template;

    // load main instance
    if (mainInstanceId) {
      if (!state.publicTemplate) {
        state.mainInstance = await instanceApi.getOne(blueprintId, mainInstanceId);
        state.instanceByBlueprint = { [blueprintId]: state.mainInstance };
      } else {
        state.mainInstance = await publicTemplatesDataSystemApi.instancesGetOne(blueprintId, mainInstanceId);
        state.instanceByBlueprint = { [blueprintId]: state.mainInstance };
      }
    } else {
      state.mainInstance = null;
    }

    await Actions.loadTemplateData(templateId, template, true);
    if (!state.publicTemplate) {
      await Actions.fetchRoles();
    }

    state.templateLoading = false;
  },

  async fetchRoles() {
    state.roles = await roleSubtenantApi.getAllForUser();
  },

  async loadTemplateData(templateId, template = null, main = false) {
    // template
    let localTemplate = template;
    if (!localTemplate) {
      localTemplate = await Actions.fetchTemplate(templateId);
    }
    if (main) {
      state.template = { main: localTemplate };
    } else {
      state.template = {
        ...state.template,
        [templateId]: localTemplate,
      };
    }

    // load blueprints
    const onlyNotLoadedBlueprints = state.blueprintIds.filter(id => {
      return !state.blueprints.some(item => item._id === id);
    });

    if (onlyNotLoadedBlueprints.length) {
      let blueprints;
      if (!state.publicTemplate) {
        blueprints = await BlueprintApi.getAllWithFields(onlyNotLoadedBlueprints);
      } else {
        const blueprintsAndFields = await publicTemplatesDataSystemApi.getAllWithFields(localTemplate._id, onlyNotLoadedBlueprints);
        blueprints = blueprintsAndFields.blueprints;
      }
      await Promise.all(
        blueprints.map(async blueprint => {
          state.blueprints.push(blueprint);
        })
      );
    }
    // load instances list
    await Promise.all(
      state.instanceListIds
        .filter(item => item.mainTemplateId === localTemplate._id)
        .map(async item => {
          if (!state.instanceList[item.objectId] || !main) {
            const defaultParams = item?.defaultParams ?? {};

            let query = Getters.getInstanceListQueryParams(item.objectId, item.blueprintId);
            query = { ...defaultParams };

            await Actions.fetchInstanceList(item.objectId, item.blueprintId, query, false);
          }
        })
    );

    // generate templates
    await Actions.generateTemplate();
  },

  async fetchInstanceList(objectId, blueprintId, query = {}, regenerateTemplateHtml = true) {
    state.lastQuery[objectId] = { [blueprintId]: query };
    state.fetchInstanceListLoading = {
      ...state.fetchInstanceListLoading,
      [objectId]: true,
    };

    if (state.templateHtml.some(item => item.objectId === objectId && !isEmpty(item.html))) {
      state.contentHtmlLoading = { [objectId]: true };
    }
    const filterConditions = await FilterConditions(query.filterConditions);

    state.filterConditions = {
      ...state.filterConditions,
      [objectId]: filterConditions,
    };

    const queryStripParams = { ...query };
    delete queryStripParams.filterConditions;

    Mutations.SET_INSTANCE_LIST_QUERY_PARAMS(objectId, blueprintId, query);
    const openInstance = state.mainInstance;
    let response = null;
    if (!state.publicTemplate) {
      response = await instanceApi.getAll(blueprintId, queryStripParams, filterConditions, openInstance);
    } else {
      response = await publicTemplatesDataSystemApi.instancesGetAll(
        state.template.main._id,
        blueprintId,
        queryStripParams,
        filterConditions,
        openInstance
      );
    }

    // query.limit

    state.instanceList = {
      ...state.instanceList,
      [objectId]: { [blueprintId]: response },
    };

    state.fetchInstanceListLoading = {
      ...state.fetchInstanceListLoading,
      [objectId]: false,
    };

    if (regenerateTemplateHtml) {
      await Actions.generateTemplate(objectId);
    }
    state.contentHtmlLoading = {};

    // pagination
    Mutations.SET_UPDATE_PAGINATION(objectId, {
      page: query.page,
      limit: query.limit,
      totalCount: response.totalCount,
    });
  },

  async fetchInstance(objectId, blueprintId, instanceId) {
    const instance = Getters.getInstanceByBlueprint(blueprintId);
    if (instance && instance._id.toString() === instanceId.toString()) {
      return instance;
    }

    state.fetchInstanceLoading = { [objectId]: { [blueprintId]: true } };
    let instanceData = null;
    if (!state.publicTemplate) {
      instanceData = await instanceApi.getOne(blueprintId, instanceId);
    } else {
      instanceData = await publicTemplatesDataSystemApi.getOne(blueprintId, instanceId);
    }

    state.instanceByBlueprint = {
      ...state.instanceByBlueprint,
      [blueprintId]: instanceData,
    };

    state.fetchInstanceLoading = { [objectId]: { [blueprintId]: false } };
    return instanceData;
  },

  async fetchBlueprint(blueprintIds) {
    let blueprints = null;

    if (!state.publicTemplate) {
      blueprints = await BlueprintApi.getAllWithFields(blueprintIds);
    } else {
      const blueprintsAndfields = await publicTemplatesDataSystemApi.getAllWithFields(state.template._id, blueprintIds);
      blueprints = blueprintsAndfields.blueprints;
    }
    blueprints.forEach(blueprint => {
      state.blueprints.push(blueprint);
    });
    return blueprints;
  },
  /* eslint-disable-next-line no-unused-vars */
  async updateInstances(objectId, blueprintId, instanceId, instanceData, selectedOwnerRoleId) {
    const data = {
      instanceData,
      instanceIds: instanceId,
    };
    state.createdEditedDeletedInstance = {
      status: true,
      blueprintId,
      objectId,
    };
    if (instanceId) {
      const { instanceList } = await instanceApi.patchMany(blueprintId, instanceId, selectedOwnerRoleId, data);
      if (instanceList) {
        if (instanceList.error) {
          return { error: true, message: instanceList.message };
        }
        const list = Getters.getInstanceList(objectId, blueprintId);
        if (list) {
          state.instanceList[objectId][blueprintId].instanceList = list.instanceList.map(item => {
            const instance = instanceList.find(obj => obj._id.toString() === item._id.toString());
            if (instance) {
              return instance;
            }
            return item;
          });
        }
      }
    }
    return null;
  },
  async createEditInstance(objectId, blueprintId, instanceId, instanceData, selectedOwnerRoleId, fields) {
    state.createdEditedDeletedInstance = {
      status: true,
      blueprintId,
      objectId,
    };
    if (instanceId) {
      const instance = await instanceApi.patchOne(blueprintId, instanceId, selectedOwnerRoleId, instanceData);

      if (instance.error) {
        return { error: true, message: instance.message };
      }
      const list = Getters.getInstanceList(objectId, blueprintId);
      if (list) {
        state.instanceList[objectId][blueprintId].instanceList = list.instanceList.map(item => {
          if (item._id.toString() === instance._id.toString()) {
            return instance;
          }
          return item;
        });
      }
      Mutations.SET_INSTANCE(blueprintId, instance);
      state.createdEditedDeletedInstance = {
        status: false,
        blueprintId,
        objectId,
      };
    } else {
      const instance = await instanceApi.postOne(blueprintId, selectedOwnerRoleId, instanceData, fields);
      state.createdEditedDeletedInstance = {
        status: false,
        blueprintId,
        objectId,
      };

      if (instance.error) {
        return { error: true, message: instance.message };
      }
      return { error: false };
    }
    return null;
  },

  async deleteInstance(objectId, blueprintId, instanceId) {
    state.createdEditedDeletedInstance = {
      status: true,
      blueprintId,
      objectId,
    };
    const deletedInstance = await instanceApi.deleteOne(blueprintId, instanceId);
    if (deletedInstance.error) {
      return { error: true, message: deletedInstance.message };
    }
    state.createdEditedDeletedInstance = {
      status: false,
      blueprintId,
      objectId,
    };
    return null;
  },

  async generateTemplate(objectId = null) {
    await Promise.all(
      state.templateHtml.map(async template => {
        if (objectId && template.objectId !== objectId) {
          return;
        }
        if (!isEmpty(template.html)) {
          const content = await DynamicTemplate(
            template.html,
            template.objectId,
            template.blueprintId,
            template.contentType,
            template?.settings ?? {},
            template?.replaceDiacritics ?? false
          );
          state.contentHtml = {
            ...state.contentHtml,
            [`${template.objectId}-${template.contentType}`]: `<div>${content}</div>`,
          };
        }
        if (!isEmpty(template.htmlAfter)) {
          const content = await DynamicTemplate(
            template.htmlAfter,
            template.objectId,
            template.blueprintId,
            template.contentType,
            template?.settings ?? {},
            template?.replaceDiacritics ?? false
          );
          state.contentHtmlAfter = {
            ...state.contentHtmlAfter,
            [`${template.objectId}-${template.contentType}`]: `<div>${content}</div>`,
          };
        }
      })
    );
    return null;
  },

  // parse template
  async fetchTemplate(templateId) {
    state.fetchTemplateLoading = true;
    let template = null;
    if (!state.publicTemplate) {
      template = await templatesDataSystemApi.getOneById(templateId);
    } else {
      template = await publicTemplatesDataSystemApi.getOneById(templateId);
    }
    state.fetchTemplateLoading = false;
    await Actions.parseTemplate(template, template._id);
    return template;
  },

  async parseTemplate(template, mainTemplateId) {
    await Promise.all(
      template.columnGroups.map(async item => {
        if (item.object) {
          // blueprints
          state.blueprintIds.push(item.object.blueprintId);
          // templates
          state.templateHtml.push({
            objectId: item.object._id,
            blueprintId: item.object.blueprintId,
            contentType: item.object.view.type,
            html: item.object.view?.contentHtml ?? '',
            htmlAfter: item.object.view?.contentHtmlAfter ?? '',
            settings: item.object.view?.contentHtmlSettings ?? {},
            simpleListView: item.object.view.default?.simpleListView ?? null,
            replaceDiacritics: item.object.view.default?.replaceDiacritics ?? null,
          });

          if (item.object.view.type === 'list') {
            // instances list
            state.instanceListIds.push({
              mainTemplateId,
              objectId: item.object._id,
              blueprintId: item.object.blueprintId,
              simpleListView: item.object.view.default?.simpleListView ?? null,
              defaultParams: {
                templateId: mainTemplateId,
                objectId: item.object._id,
                filterConditions: item.object.view?.filters ?? [],
                page: item.object.view?.startPage ?? 1,
                limit: item.object.view?.itemsPerPage ?? 10,
                defaultOrderField: item.object.view.default?.order?.fieldId ?? null,
                defaultOrder: item.object.view?.default?.order?.order ?? null,
                convertOwnerIdToOwnerDetails: item.object.view?.default?.convertOwnerIdToOwnerDetails ?? false,
              },
            });
          }
        } else {
          await Actions.parseTemplate(item, mainTemplateId);
        }
      })
    );
  },
};

export const NewTemplatesGetters = Getters;
export const NewTemplatesMutations = Mutations;
export const NewTemplatesActions = Actions;
