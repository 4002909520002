<template>
  <a-collapse v-model:open="collapseActiveKey" accordion>
    <a-collapse-panel v-for="{ category, widgets } in WidgetsByCategories" :key="category" :header="category">
      <div>
        <a-button
          v-for="(widget, index) in widgets"
          :key="index"
          class="fieldItem"
          :disabled="widget.disabled"
          @click="() => createField(widget.type, widget.requiresRuleset)"
        >
          {{ widget.label }}
        </a-button>
      </div>
    </a-collapse-panel>
  </a-collapse>
</template>

<script>
import { FormBuilderMutations, FormBuilderActions } from '../formBuilder.store';

const WidgetsByCategories = [
  {
    category: 'Single-valued Fields',
    widgets: [
      { label: 'Single Line Text', type: 'single_line_text' },
      { label: 'Paragraph Text', type: 'paragraph_text' },
      { label: 'Number', type: 'number' },
      { label: 'Checkbox', type: 'checkbox' },
      { label: 'Single Choice', type: 'single_choice' },
      { label: 'Dropdown', type: 'dropdown' },
      { label: 'Date', type: 'date' },
      { label: 'Time', type: 'time' },
      {
        label: 'Reference',
        type: 'reference_single',
        requiresRuleset: true,
      },
    ],
  },
  {
    category: 'Multiple-valued Fields',
    widgets: [
      { label: 'Multiple Choice', type: 'multiple_choice' },
      { label: 'Multiple Select', type: 'multiple_select' },
      { label: 'Text List', type: 'text_list' },
      { label: 'Number List', type: 'number_list' },
      { label: 'Date List', type: 'date_list' },
      {
        label: 'Reference List',
        type: 'reference_list',
        requiresRuleset: true,
      },
    ],
  },
  {
    category: 'Specific Fields',
    widgets: [
      { label: 'Email', type: 'email' },
      { label: 'Phone Number', type: 'phone_number', requiresRuleset: true },
      { label: 'Address', type: 'address', disabled: true },
      { label: 'Website URL', type: 'website_url' },
      { label: 'Password', type: 'password' },
      { label: 'File Upload', type: 'file_upload' },
      { label: 'Static Text', type: 'simple_text' },
      { label: 'Text Editor', type: 'text_editor' },
      { label: 'CNP', type: 'cnp' },
    ],
  },
  {
    category: 'Layout Fields',
    widgets: [
      { label: 'Heading', type: 'heading', disabled: true },
      { label: 'Image', type: 'image', disabled: true },
      { label: 'Section', type: 'section', disabled: true },
      { label: 'Page', type: 'page', disabled: true },
    ],
  },
];

export default {
  data() {
    return {
      WidgetsByCategories,
      collapseActiveKey: 'Standard Fields',
      selectedWidgetType: null,
    };
  },
  methods: {
    async createField(selectedWidgetType, requiresRuleset) {
      if (requiresRuleset) {
        FormBuilderMutations.SET_WIDGET_TYPE_TO_CREATE(selectedWidgetType);
      } else {
        await FormBuilderActions.createField(selectedWidgetType);
      }
    },
  },
};
</script>

<style scoped>
.fieldContainer {
  display: flex;
  flex-wrap: wrap;
}
.fieldItem {
  flex: 50%;
  margin: 5px;
  width: 130px;
  background-color: #fafafa;
}
</style>
