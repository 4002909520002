<template>
  <div>
    <h3 v-if="(object?.view?.default?.showTitle && blueprint?.name) ?? false">
      {{ object?.view?.default?.customTitle?.length ? object?.view?.default?.customTitle : blueprint?.name }}
    </h3>

    <div class="clearfix"></div>

    <a-button @click="exportXml" v-if="user.isSuperAdmin">TEST EXPORT</a-button>

    <a-spin :spinning="contentHtmlLoading" style="width: 100%">
      <RenderHtml
        v-if="localRenderedContentHtml"
        @buttonClicked="renderHtmlButtonClicked"
        :content="localRenderedContentHtml"
        :key="renderHtmlTemplateKey"
      />
    </a-spin>

    <div style="margin-right: 10px" class="float-right" v-if="blueprint && (object.view.default?.showMassEmail ?? false)">
      <a-button type="primary" @click="showHideMassEmail">{{
        (object?.view?.default?.massEmailButtonLabel ?? '') !== '' ? object?.view?.default?.massEmailButtonLabel : 'Send E-mail'
      }}</a-button>
    </div>

    <template v-if="object.blueprintId">
      <div v-if="blueprint && instance && (object?.clickItem?.templateId ?? true) && !loadTemplate">
        <template v-if="(object.view?.default?.showAutoData ?? true) || showAutoData">
          <template v-if="object.clickItem?.templateId && !lastDepth">
            <RecursiveColumns v-if="template" :viewType="viewType" :lastDepth="true" :instanceId="localInstanceId" :template="template" />
          </template>
          <template v-else>
            <template v-if="showAutoData && !showAutoDataInPopup">
              <a-button @click="showAutoData = false">Close</a-button>
              <a-divider />
            </template>

            <component
              :is="showAutoDataInPopup ? 'a-modal' : 'div'"
              :footer="false"
              :width="'1200px'"
              v-model:open="showAutoData"
              :destroyOnClose="true"
              :title="(instance ? 'Edit ' : 'Add new ') + blueprint?.name"
              :maskClosable="false"
              @cancel="showAutoData = false"
            >
              <div v-if="viewingMode === 'view'">
                <ViewInstance :object="object" :showFields="object.view?.itemFields ?? []" :blueprint="blueprint" :instance="instance" />
              </div>

              <div v-else>
                <CreateEditInstance
                  :object="object"
                  :filterConditions="filterConditions"
                  :showEditButton="object.view.default?.showEditButton ?? true"
                  :editButtonLabel="object.view.default?.editButtonLabel ?? ''"
                  :showDeleteButton="object.view.default?.showDeleteButton ?? true"
                  :deleteButtonLabel="object.view.default?.deleteButtonLabel ?? ''"
                  :showFields="object.view?.itemFields ?? []"
                  :blueprint="blueprint"
                  :instance="instance"
                  @closePopup="showAutoData = false"
                />
              </div>
            </component>
          </template>
        </template>
        <a-modal
          v-if="object.view.default?.showMassEmail ?? false"
          :footer="false"
          :width="'1200px'"
          :destroyOnClose="true"
          :open="showMassEmail"
          :maskClosable="false"
          :title="(object?.view?.default?.massEmailButtonLabel ?? '') || 'Mass E-mail'"
          @cancel="showMassEmail = false"
        >
          <MassEmail
            :object="object"
            :blueprint="blueprint"
            :instance="instance"
            :itemFields="object.view?.itemFields.filter(item => item.widgetType === 'email') ?? []"
            @closePopup="showMassEmail = false"
          />
        </a-modal>
      </div>
      <template v-else>
        <loading-spinner />
      </template>
    </template>
  </div>
</template>

<script>
import RenderHtml from '@templateDataSystem/views/components/RenderHtml';
import MassEmail from '@templateDataSystem/views/components/MassEmail';
import { NewTemplatesActions, NewTemplatesGetters, NewTemplatesMutations } from '@templateDataSystem/shared/newTemplateDataSystem.store';
import { objectId } from '@/core/utils/array-manipulation';
import templatesDataSystemApi from '@templateDataSystem/api/templatesDataSystem.api';
import { UserGetters } from '@userManagement/user.store';
import ViewInstance from '@templateDataSystem/views/components/ViewInstanceAsText/ViewInstanceAsText.vue';
import { defineAsyncComponent } from 'vue';
import CreateEditInstance from './CreateEditInstance.vue';

const RecursiveColumns = defineAsyncComponent(() => import('@/apps/templateDataSystem/views/components/RecursiveColumns.vue'));
export default {
  name: 'ElementDataItemView',
  props: ['object', 'viewType', 'lastDepth', 'propsBlueprint', 'propsInstance', 'instanceId', 'blueprintId'],
  components: {
    ViewInstance,
    RenderHtml,
    CreateEditInstance,
    MassEmail,
    RecursiveColumns,
  },
  data() {
    return {
      loading: false,
      loadTemplate: false,
      showMassEmail: false,
      localInstanceId: this.$route.params.instanceId ?? null,

      filterConditions: [],

      renderHtmlTemplateKey: objectId(),

      instance: null,

      showAutoData: false,
      showAutoDataInPopup: false,

      localRenderedContentHtml: null,
    };
  },
  watch: {
    async instanceId(value) {
      this.localInstanceId = value ?? null;
      if (value) {
        this.instance = await this.loadInstance();
      }
    },

    '$route.params.instanceId': async function (value) {
      this.localInstanceId = value ?? null;
      if (value) {
        this.instance = await this.loadInstance();
      }
    },

    storeInstance(value) {
      this.instance = value;
      NewTemplatesActions.generateTemplate(this.object._id);
    },

    renderedContentHtml(data) {
      this.localRenderedContentHtml = data;
      this.renderHtmlTemplateKey = objectId();
    },

    async instance(value) {
      if (!value) {
        this.instance = await this.loadInstance();
      }
    },
  },
  computed: {
    user: UserGetters.getUser,
    userRoles: NewTemplatesGetters.getRoles,
    storeInstance() {
      return NewTemplatesGetters.getInstance(this.object.blueprintId, this.localInstanceId);
    },
    viewingMode() {
      return this.$route.query.mode || 'edit';
    },

    template() {
      return NewTemplatesGetters.getTemplate(this.object.clickItem?.templateId);
    },

    contentHtmlLoading() {
      return NewTemplatesGetters.getContentHtmlLoading(this.object._id);
    },
    userRolesWhichCanCreate() {
      return (this.userRoles ?? []).filter(role => {
        const permission = role.permissionByBlueprintId[this.blueprint?._id];
        if (!permission) {
          return false;
        }
        return permission.createAuthority.mode === 'yes';
      });
    },

    blueprint() {
      if (this.propsBlueprint) {
        return this.propsBlueprint;
      }
      return NewTemplatesGetters.getBlueprint(this.object.blueprintId ?? this.blueprintId);
    },
    renderedContentHtml() {
      return NewTemplatesGetters.getContentHtml(this.object._id, this.object.view.type);
    },
  },

  async created() {
    if (this.instanceId) {
      this.localInstanceId = this.instanceId;
    }

    this.instance = await this.loadInstance();

    if (this.object.clickItem?.templateId) {
      this.loadTemplate = true;
      await NewTemplatesActions.loadTemplateData(this.object.clickItem.templateId);
      this.loadTemplate = false;
    }

    this.localRenderedContentHtml = this.renderedContentHtml;
  },
  methods: {
    async exportXml() {
      const form = {
        instanceId: this.localInstanceId,
        beautify: true,
      };

      const data = await templatesDataSystemApi.exportTemplate(this.$route.params.templateId, form);
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${(this.blueprint?.name ?? 'file').toLowerCase()}.xml`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    },
    showHideMassEmail() {
      this.showMassEmail = true;
    },
    generatePDF() {
      NewTemplatesMutations.SET_DOWNLOAD_PDF(true);
    },

    async loadInstance() {
      let instance = null;
      if (this.object.blueprintId) {
        if (this.propsInstance) {
          instance = this.propsInstance;
        } else {
          instance = NewTemplatesGetters.getInstance(this.object.blueprintId, this.localInstanceId);
        }
        if (!instance) {
          instance = await NewTemplatesActions.fetchInstance(this.object._id, this.object.blueprintId, this.localInstanceId);
        }
      }
      return instance;
    },
    renderHtmlButtonClicked(data) {
      if (data.open) {
        console.log('from emit');
        console.log(data);
        console.log(this.showAutoData);
        if (!this.showAutoData) {
          if (data.open === 'inPopup') {
            this.showAutoDataInPopup = true;
            this.showAutoData = true;
            console.log(this.showAutoDataInPopup);
            console.log(this.showAutoData);
          } else if (data.open === 'inPage') {
            this.showAutoData = true;
          }
        } else {
          this.showAutoDataInPopup = false;
          this.showAutoData = false;
        }
      }
    },
  },
};
</script>
