<template>
  <a-select v-model:value="selectedBlueprintId" :placeholder="'Select Blueprint'" style="min-width: 200px" show-search @change="change">
    <a-select-option v-for="blueprint in blueprintList" :key="blueprint._id" :value="blueprint.name">
      {{ blueprint.name }}
    </a-select-option>
  </a-select>
</template>

<script>
import { blueprintApi } from '@dataSystem/api';

export default {
  name: 'BlueprintSelect',
  props: ['modelValue', 'placeholder'],
  emits: ['update:modelValue', 'change'],
  data() {
    return {
      selectedBlueprintId: null,
      blueprintList: [],
    };
  },
  async mounted() {
    this.blueprintList = await blueprintApi.getAll();
    if (this.modelValue) {
      this.selectedBlueprintId = this.blueprintList.find(blueprint => blueprint._id === this.modelValue).name;
    }
  },
  methods: {
    change(value, option) {
      this.$emit('update:modelValue', option.key);
      this.$emit('change');
    },
  },
};
</script>
